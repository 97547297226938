import React from 'react';
import './MainPage.css';
import '../../App.css';
import Header from '../Header/Header';
import Note from '../Note/Note';
import Footer from '../Footer/Footer';

import { useTranslation } from 'react-i18next';
import '../../i18next';


function MainPage() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }
    return (
        <>
            <Header title={t('headerTitle')} />
            <Note />
            <Footer />
        </>
    )
}

export default MainPage