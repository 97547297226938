import React from 'react';
import './Footer.css';
import '../../App.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../i18next'

function Footer() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }


    return (
        <footer id="footer">
            <div id="nav" className="section group">
                <nav>
                    <div className="col span_2_of_6">
                        <Link to='/'><strong>{t('createNewNote')}</strong></Link><br />
                        <Link to='/contact'>{t('comments')}</Link><br />
                        <Link to='/faq'>FAQ</Link><br />
                    </div>
                    <div className="col span_1_of_6">
                        <Link to='/support'>{t('support')}</Link><br />
                        <Link to='/privacy'>{t('privacy')}</Link><br />
                        <Link to='/about'>{t('about')}</Link>
                    </div>


                </nav>
                <div className="col span_2_of_6">

                    <form id="locale_form">
                        <select onChange={(e) => i18n.changeLanguage(e.target.value)} name="param" id="locale_select">
                            <option value="ru">({t('default')})</option>
                            <option value="ru">Русский</option>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="de">Deutsch</option>
                            <option value="fr">Français</option>
                            <option value="it">Italiano</option>
                            <option value="pl">Polski</option>
                            <option value="pt">Português</option>
                            <option value="ro">Romana</option>
                            <option value="sv">Svenska</option>
                            <option value="tr">Türkçe</option>
                            <option value="uk">українська мова</option>
                            <option value="zh_CN">汉语</option>
                            <option value="zh_TW">漢語</option>
                            <option value="cs">čeština</option>
                            <option value="nl">Dutch</option>
                            <option value="th">ไทย</option>
                        </select>
                    </form>

                </div>

            </div>
            <div id="footnote">
                private messages <span className="version">2021-01-08</span> |
                © <a href="#">notepad</a>
            </div>

        </footer>)
}

export default Footer;