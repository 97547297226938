import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import '../Note/Note.css';
import './ReadNotePage.css';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import ClipboardJS from "clipboard";
import { useTranslation } from 'react-i18next';

import '../../i18next'

function ReadNotePage() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    const [noteContent, setNoteContent] = useState('');

    new ClipboardJS(".copy");
    let navigate = useNavigate();

    const location = useLocation();
    const params = useParams();

    const [notePassword, setNotePassword] = useState('');
    const [passwordFormVisible, setPasswordFormVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    

    function getNote() {
        const url = `${window.location.origin}/notes/${params.key}${encodeURIComponent(location.hash)}?password=${notePassword}`;
        fetch(url)
            .then(response => {
                switch (response.status) {
                    case 200:
                        return response.json();
                    case 403:
                        throw new Error('Invalid password!');
                    case 404:
                        navigate('/notfound');
                }
            })
            .then(data => {
                setNoteContent(data.content);
            })
            .catch(() => {
                if (notePassword) {
                    setErrorVisible(true)
                }

                setPasswordFormVisible(true)
            });
    }

    return (
        <>
            <Header title="Send anonymous notes via Private Note." />
            {!noteContent ?
                (<div id="content" className="check-ligth-layout">
                    <div id="content">
                        {!passwordFormVisible ?
                            (<div id="confirm_read_note">
                                <div id="link_ok">
                                    <h1>{t('readNoteHeader')}</h1>
                                    <p>{t('readNoteHeaderPar')} <strong>{params.key + location.hash}</strong>.<br /></p>
                                    <div>
                                        <div className="section group">
                                            <div className="col span_3_of_6">
                                                <button id="confirm_button" className="primary_button" type="submit"

                                                    onClick={(e) => getNote()}

                                                >{t('readNoteHeaderBtnYes')}</button>
                                            </div>
                                            <div className="col span_3_of_6">
                                                <Link to='/' className="button">{t('readNoteHeaderBtnNo')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                            :
                            (
                                <div id="password_form">
                                    <div className="warning_block">
                                        {t('warningBlock')}                           </div>
                                    <h3>{t('textPassword')}  </h3>
                                    <div className="col span_3_of_6 note_password_wrapper">
                                        <input id="note_password" type="password" value={notePassword} onChange={(e) => setNotePassword(e.target.value)} />
                                        {errorVisible && (<span className="error" id="error_password_incorrect">
                                            {t('errorPassword')}
                                        </span>)}
                                    </div>
                                    <div className="section group">
                                        <div className="col span_3_of_6">
                                            <button id="decrypt_button" className="primary_button"
                                                onClick={(e) => getNote()}
                                            >{t('btnCont')}</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>)
                :
                (<div id="content" className="check-ligth-layout">
                    <div id="content">
                        <div id="read_note" >
                            <h1>{t('contentsNote')}</h1>

                            <div id="ok_content" className="">
                                <div id="info_destroyed" className="warning_block">
                                    {t('destroyedNote')}                            </div>
                                <div id="info_expires" className="warning_block hidden">
                                    {t('destroyedNoteTime')}<span id="info_expires_text"></span>.
                                </div>
                                <textarea id="note_contents" className="" readOnly={true} value={noteContent}></textarea>
                                <div className="section group">
                                    <div className="col span_2_of_6">
                                        <button id="select_text" className="small_button copy" data-clipboard-action="copy"
                                            data-clipboard-target="#note_contents">{t('selectTextBtn')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            <Footer />
        </>
    )
}

export default ReadNotePage