import './App.css';
import Footer from './components/Footer/Footer';
import MainPage from './components/MainPage/MainPage';
import PageNotFound from './components/PageNotFound/PageNotFound';
import ContactPage from './components/ContactPage/ContactPage';
import FaqPage from './components/FaqPage/FaqPage';
import SupportPage from './components/SupportPage/SupportPage';
import PrivacyPage from './components/PrivacyPage/PrivacyPage';
import AboutPage from './components/AboutPage/AboutPage';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreatedNotePage from './components/CreatedNotePage/CreatedNotePage';
import ReadNotePage from './components/ReadNotePage/ReadNotePage';
import { useTranslation } from 'react-i18next';
// import { useEffect } from 'react';



function App() {
  
  const { t, i18n } = useTranslation();



  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={<MainPage />} />

        <Route path='/created/:key' element={<CreatedNotePage />} />

        <Route path='/:key' element={<ReadNotePage />} />

        {/* Параметр path указываем по какому адресу будут доступна эта страница */}
        <Route path="/contact" element={<ContactPage />} />

        <Route path="/faq" element={<FaqPage />} />

        <Route path="/support" element={<SupportPage />} />

        <Route path="/privacy" element={<PrivacyPage />} />

        <Route path="/about" element={<AboutPage />} />

        {/* Если человек ввел другой адрес, то показываем страницу 404 */}
        <Route path="*" element={<PageNotFound />} />


      </Routes>
    </BrowserRouter >
  );
}

export default App;
