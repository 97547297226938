import React from 'react';
import './Header.css';
import logo from '../../assets/privonte.png';
import { Link } from 'react-router-dom';

function Header(props) {
    const { title } = props;
    return (
        <div id="header_bg">
            <header className="header" id="header">
                <p className="h1">
                    <Link to='/' title="Send anonymous notes via Private Note.">
                        <img src={logo} alt="Logo" height="55px" />

                    </Link>

                </p>
                <h2> {title}</h2>
            </header>
        </div>
    )
}

export default Header;