import React from 'react';
import './SupportPage.css';
import '../../App.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../i18next'

function SupportPage() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    return (
        <>
            <Header title="Support" />
            <div id="content" className="check-ligth-layout">
                <div className="content check-light-content">
                    <h1>{t('supportHeader')}</h1>

                    <p>{t('supportParagraphOne')}&nbsp;<a href="mailto:info@privonte.com">info@privonte.com</a>.</p>

                    <p>{t('supportParagraphTwo')}</p>

                    <p>{t('supportParagraphThree')}&nbsp;<Link to='/faq'>{t('supportParagraphThreeFaq')}</Link>&nbsp;{t('supportParagraphThreeBlock')}&nbsp;<Link to='/privacy'>{t('supportParagraphThreeLink')}</Link>.</p>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default SupportPage