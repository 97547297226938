import React from 'react';
import './FaqPage.css';
import '../../App.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../i18next'

function FaqPage() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }
    return (
        <>
            <Header title="FAQ" />
            <div id="content" className="check-ligth-layout">
                <div className="content check-light-content">
                    <h1>FAQ</h1>

                    <h3>{t('faqHeader')}</h3>

                    <p>{t('faqParagraph')}</p>

                    <h3>{t('faqHeaderTwo')}</h3>

                    <p>{t('faqParagraphTwo')}</p>

                    <h3>{t('faqHeaderThree')}</h3>

                    <p>{t('faqParagraphThree')}</p>

                    <h3>{t('faqHeaderFour')}</h3>

                    <p>{t('faqParagraphFour')}</p>

                    <h3>{t('faqHeaderFive')}</h3>

                    <p>{t('faqParagraphFive')}</p>

                    <h3>{t('faqHeaderSix')}</h3>

                    <p>{t('faqParagraphSix')}</p>

                    <h3>{t('faqHeaderSeven')}</h3>

                    <p>{t('faqParagraphSeven')}</p>

                    <h3>{t('faqHeaderEight')}</h3>

                    <p>{t('faqParagraphEight')}&nbsp;<Link to='/privacy'>Privacy Policy</Link></p>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default FaqPage