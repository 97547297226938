import React from 'react';
import './PrivacyPage.css';
import '../../App.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';

import '../../i18next'

function PrivacyPage() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    return (
        <>
            <Header title="Privacy" />
            <div id="content" className="check-ligth-layout">
                <div className="content check-light-content">
                    <h1>{t('privacyHeader')}</h1>

                    <p><em>{t('privacyParagraphOne')}</em></p>

                    <p>{t('privacyParagraphTwo')}</p>

                    <h3>{t('privacyHeaderTwo')}</h3>

                    <p>{t('privacyParagraphThree')}</p>

                    <p>{t('privacyParagraphFour')}</p>

                    <p>{t('privacyParagraphFive')}</p>

                    <h3>{t('privacyHeaderThree')}</h3>

                    <p>{t('privacyParagraphSix')}</p>

                    <p>{t('privacyParagraphSeven')}</p>

                    <p>{t('privacyParagraphEight')}</p>

                    <p>{t('privacyParagraphNine')}</p>

                    <p>{t('privacyParagraphTen')}</p>

                    <p>{t('privacyParagraphEleven')}</p>

                    <h3>{t('privacyHeaderFour')}</h3>

                    <p>{t('privacyHeaderFourParagraph')}</p>

                    <h3>{t('privacyHeaderFive')}</h3>

                    <p>{t('privacyHeaderFiveParagraph')}</p>

                    <p>{t('privacyHeaderFiveParagraphTwo')}</p>

                    <h3>{t('privacyHeaderSix')}</h3>

                    <p>{t('privacyHeaderSixParagraph')}</p>

                    <h3>{t('privacyHeaderSeven')}</h3>

                    <p>{t('privacyHeaderSevenParagraph')}</p>

                    <h3>{t('privacyHeaderEight')}</h3>

                    <p>{t('privacyHeaderEightParagraph')}</p>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPage