import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import '../Note/Note.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import ClipboardJS from "clipboard";
import { useTranslation } from 'react-i18next';

import '../../i18next'


function CreatedNotePage() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    new ClipboardJS(".copy");

    const [paramsHelpVisible, setParamsHelpVisible] = useState(false);
    const [type, setType] = useState('password');

    const location = useLocation();
    const params = useParams();

    function getLink() {
        return `${window.location.protocol}//${window.location.hostname}/${params.key}${location.hash}`
    }

    const password = sessionStorage.getItem('password');
    const date = sessionStorage.getItem('date');

    return (
        <>
            <Header title="Send anonymous notes via Private Note." />
            <div id='content'>
                <div id="created_note" >
                    <h3>{t('linkNoteDone')}<a id="created_note_help_toggle" className="help_button"
                        onClick={() => setParamsHelpVisible(!paramsHelpVisible)}
                    >?</a></h3>
                    {paramsHelpVisible && (<div id="created_note_help" className="help_block">
                        {t('helpCopyLink')}
                    </div>)}
                    <div className="section group">
                        <label>
                            <input id="note_link_input" type="text" readOnly={true} value={getLink()} />
                            {!date ? (<span id="info_read_once" className="caption">{t('deleteAfterRead')}</span>)
                                : (<span id="info_expires" className="caption">{t('destroyedNoteTime')}<span id="info_expires_text">{date}</span>.</span>)}
                        </label>
                    </div>
                    <div className="section group">
                        <div className="col span_1_of_6">
                            <button id="select_link" className="small_button copy" data-clipboard-action="copy"
                                data-clipboard-target="#note_link_input">{t('copyLink')}</button>
                        </div>
                        <div className="col span_1_of_6">
                            <a id="mailto_link" className="small_button" href={`mailto:?body=${getLink()}`}> E-mail</a>
                        </div>
                        <div className="col span_2_of_6 right">
                            <Link to={`/${params.key}${location.hash}`} id="destroy_link" className="danger small_button">{t('deleteNow')}</Link>
                            {/* <a id="show_link" className="small_button" href="https://pirvnota.com/">Прочитать записку</a> */}
                        </div>
                    </div>
                    {password && (<div id="note_password_block">
                        <h3>{t('secretPasswordHeader')}</h3>
                        <div className="section group">
                            <label>
                                {t('needPassword')}
                                <input id="note_password_input" type={type} value={password} readOnly={true} />
                            </label>
                        </div>
                        <div className="section group">
                            <div className="col span_2_of_6">
                                <button id="show_password" className="small_button"
                                    onClick={() => setType('text')}
                                >{t('showPassword')}</button>
                                <button id="hide_password" className="small_button"
                                    onClick={() => setType('password')}
                                >{t('hidePassword')}</button>
                            </div>
                            <div className="col span_2_of_6">
                                <button id="select_password" className="small_button copy" data-clipboard-action="copy"
                                    data-clipboard-target="#note_password_input">{t('copyPassword')}</button>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default CreatedNotePage