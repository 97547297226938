import React from 'react';
import './ContactPage.css';
import '../../App.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../i18next'

function ContactPage() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    return (
        <>
            <Header title="Contact" />
            <div id="content" className="check-ligth-layout">
                <div className="content check-light-content">
                    <h1>{t('contactHeader')}</h1>

                    <p><br />
                    {t('contactParagraph')}&nbsp;<a href="mailto:info@privonte.com">info@privonte.com</a>.</p>

                    <p>{t('contactParagraphOne')}&nbsp;<Link to='/faq'>{t('contactLinkFaq')}</Link>&nbsp;{t('contactParagraphTwo')}&nbsp;<Link to='/privacy'>{t('contactLink')}</Link>.</p>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactPage