import React from 'react';
import './PageNotFound.css';
import logo from '../../assets/privonte.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../i18next'

function PageNotFound() {

  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  return (
    <div className="modal">
      <div className="dialog">
        <Link to='/'>
          <img className="logo" src={logo} alt="privonte" />
        </Link>
        <h1>{t('pageNotFoundHeader')}</h1>
        <p>{t('pageNotFoundParagraph')}</p>
      </div>
      <div className="footer">
        <Link to='/'>Privonte</Link>
      </div>
    </div>
  )
}

export default PageNotFound;