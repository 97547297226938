import React, { useState } from 'react';
import './Note.css';
import '../../App.css';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../i18next'


function Note() {
    sessionStorage.clear();

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    const [helpVisible, setHelpVisible] = useState(false);
    const [paramsVisible, setParamsVisible] = useState(false);
    const [content, setContent] = useState('');
    const [password, setPassword] = useState('');
    const [mail, setMail] = useState('');
    const [lifetime, setLifetime] = useState(0);
    const [checkPassword, setCheckPassword] = useState('');
    const [contentCheckVisible, setContentCheckVisible] = useState(false);

    let navigate = useNavigate();


    function handleContent(e) {
        setContent(e.target.value)
    }

    function handlePassword(e) {
        setPassword(e.target.value)
    }

    function handleMail(e) {
        setMail(e.target.value)
    }

    function handleLifetime(e) {
        setLifetime(e.target.value)
    }

    function handleCheckPassword(e) {
        setCheckPassword(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (!content) {
            setContentCheckVisible(true);
            return;
        }

        if (password !== checkPassword) {
            alert('Пароли должны совпадать!');
            return;
        }

        console.log(e.target);
        let noteInfo = {
            content: content,
            lifetime: lifetime,
            password: password,
            mail: mail
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(noteInfo)
        };


        fetch(`${window.location.origin}/notes/create`, requestOptions)
            .then(response => response.json())
            .then(data => {

                if (lifetime) {
                    const date = new Date();
                    date.setTime(date.getTime() + lifetime * 60 * 60 * 1000);
                    sessionStorage.setItem('date', date);
                }

                sessionStorage.setItem('password', password);
                navigate(`/created/${data.note_id}`)
            });
    }


    return (
        <div id="content" className="check-ligth-layout">
            <div id="new_note" className="check-ligth-content">
                <div id="create_note">
                    <div>
                        <h1>{t('newNote')}<a id="new_note_help_toggle" className="help_button"
                            onClick={() => setHelpVisible(!helpVisible)}
                        >?</a></h1>
                    </div>
                    {helpVisible && (<div id="new_note_help" className="section help_block" >
                        <p className='help_block-p'>{t('noteParagraph')}</p><br />
                        <p className='help_block-p'>{t('noteParagraphTwo')}</p><br />
                        <p className='help_block-p'>{t('noteParagraphThree')}</p><br />
                        <p className='help_block-p'>{t('noteParagraphFour')}</p><br />
                        <p className='help_block-p'>{t('noteParagraphFive')}</p><br /><br />
                        <p className='help_block-p'>{t('noteParagraphSix')}&nbsp;<Link className='link-btn' to='/about'>{t('noteParagraphLink')}</Link>.</p>
                    </div>)}
                    <textarea name="note_text" rows="4" placeholder={t('notePlaceholder')} value={content} onChange={handleContent}></textarea>
                    {contentCheckVisible && (<div id="error_note">{t('errorNote')}</div>)}
                    <div className="section group">
                        <div className="col span_2_of_6">
                            <button id="encrypt_note" className="primary_button" type="button"
                                onClick={handleSubmit}
                            >{t('createdNewNote')}</button>
                        </div>
                        <div className="col span_2_of_6 right">
                            <button type="button" id="advanced_options_show" className="button"
                                onClick={(e) => setParamsVisible(!paramsVisible)}
                            >{paramsVisible ? t('hiddenParam') : t('showParam')}<span id="options_on_notice_asterisk" className="hidden">(*)</span></button>
                            {/* <button type="button" id="advanced_options_hide" className="button hidden">Отключить параметры</button> */}
                        </div>
                    </div>
                    {paramsVisible && (<div id="advanced_options">
                        <h3>{t('destructNote')}</h3>
                        <div className="section group">
                            <div className="col span_3_of_6">
                                <label>
                                    <select name="note_duration" onChange={handleLifetime}>
                                        <option value="0">{t('deleteNoteOne')}</option>
                                        <option value="1">{t('deleteNoteTwo')}</option>
                                        <option value="24">{t('deleteNoteThree')}</option>
                                        <option value="168">{t('deleteNoteFour')}</option>
                                        <option value="720">{t('deleteNoteFive')}</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <h3>{t('secretPassword')}</h3>
                        <div className="section group">
                            <div className="col span_3_of_6">
                                <label>
                                    {t('secretPasswordEnter')}
                                    <input name="note_password" type="password" autocomplete={false} value={password} onChange={handlePassword} />
                                </label>
                            </div>
                            <div className="col span_3_of_6">
                                <label>
                                    {t('repeatPasswod')}
                                    <input name="note_password_confirmation" type="password" autocomplete={false}
                                        onChange={handleCheckPassword} />
                                </label>
                            </div>
                        </div>
                        <h3>{t('notificationNote')}</h3>
                        <div className="section group">
                            <div className="col span_3_of_6">
                                <label>
                                    {t('notificationNoteEmail')}
                                    <input name="note_email" type="email" autocomplete={false} value={mail} onChange={handleMail} />
                                </label>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}

export default Note;